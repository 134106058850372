import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import Layout from '../../components/Layouts/layout';
import SEO from '../../components/seo';
import LinkList from '../../components/LinkList/LinkList';

const GovernancePage = () => {
	const intl = useIntl();

	return (
		<Layout
			breadcrumb={{
				text: <FormattedMessage id='nav.about' />,
				path: '/about/'
			}}
			header={<FormattedMessage id='nav.governance' />}
		>
			<SEO
				description={intl.formatMessage({
					id: 'metaTags.aboutDescription'
				})}
				lang={intl.locale}
				title={intl.formatMessage({ id: 'nav.governance' })}
			/>
			<Container>
				<Row>
					<Col md={12}>
						<LinkList
							locale={intl.locale}
							links={[
								{
									path: `../../../governance/Access and Privacy Code.pdf`,
									text: <FormattedMessage id='linkNames.accessPrivacyCode' />
								},
								{
									path: '../../../Administrative Agreement Jan 29 2021.pdf',
									text: <FormattedMessage id='linkNames.adminAgreement' />
								},
								{
									path: `../../../governance/${intl.formatMessage({
										id: 'linkNames.ampLink'
									})}`,
									text: <FormattedMessage id='linkNames.amp' />
								},
								{
									path: `../../../governance/2024-01-12 General By-Law.pdf`,
									text: <FormattedMessage id='linkNames.bylaw' />
								},
								{
									path: `../../../${intl.formatMessage({
										id: 'linkNames.codeForComplianceLink'
									})}`,
									text: <FormattedMessage id='linkNames.codeForCompliance' />
								},
								{
									path: '/about/governance/compensation-disclosure',
									text: <FormattedMessage id='nav.compensation' />,
									internal: true
								},

								{
									path: `../../../${intl.formatMessage({
										id: 'linkNames.complaintsPolicyLink'
									})}`,
									text: <FormattedMessage id='linkNames.complaintsPolicy' />
									// hideFrench: true
								},

								{
									path: intl.formatMessage({
										id: 'linkNames.designationLink'
									}),
									text: <FormattedMessage id='linkNames.designationReg' />
								},
								{
									path: `../../../${intl.formatMessage({
										id: 'linkNames.expensePolicyLink'
									})}`,
									text: <FormattedMessage id='linkNames.expensePolicy' />
								},
								{
									path: intl.formatMessage({
										id: 'linkNames.nhclaLink'
									}),
									text: <FormattedMessage id='linkNames.nhcla' />,
									description: <FormattedMessage id='linkNames.nhclaDescript' />
								},
								{
									path: `../../../${intl.formatMessage({
										id: 'linkNames.procurementPolicyLink'
									})}`,
									text: <FormattedMessage id='linkNames.procurementPolicy' />
								}
							]}
						/>

						<h2>{intl.locale === 'fr' ? 'Rapports Annuel' : 'Annual Reports'}</h2>
						<LinkList
							links={[
								{
									path: `../../../${intl.formatMessage({
										id: 'linkNames.annualReportLink24'
									})}`,
									text: intl.locale === 'fr' ? '2023-24 (Anglais)' : '2023-24'
								},
								{
									path: `../../../${intl.formatMessage({
										id: 'linkNames.annualReportLink23'
									})}`,
									text: '2022-23'
								},
								{
									path: `../../../${intl.formatMessage({
										id: 'linkNames.annualReportLink22'
									})}`,
									text: '2021-22'
								},
								{
									path: `../../../${intl.formatMessage({
										id: 'linkNames.annualReportLink'
									})}`,
									text: '2020-21'
								}
							]}
						/>

						<h2>{intl.locale === 'fr' ? "Plan d'affaires" : 'Business Plan'}</h2>
						<LinkList
							links={[
								{
									path: `../../../${intl.formatMessage({
										id: 'linkNames.businessPlan2024Link'
									})}`,
									text: '2024-25'
								},
								{
									path: `../../../${intl.formatMessage({
										id: 'linkNames.businessPlan2023Link'
									})}`,
									text: '2023-24'
								},
								{
									path: `../../../${intl.formatMessage({
										id: 'linkNames.businessPlan2Link'
									})}`,
									text: '2022-23'
								},
								{
									path: `../../../${intl.formatMessage({
										id: 'linkNames.businessPlanLink'
									})}`,
									text: '2021-22'
								}
							]}
						/>

						<h2>
							<FormattedMessage id='linkNames.strategicPlan' />
						</h2>
						<LinkList
							links={[
								{
									path: `../../../${intl.formatMessage({
										id: 'linkNames.strategicLink'
									})}`,
									text: '2022-25'
								},
								{
									path: `../../../${intl.formatMessage({
										id: 'linkNames.strategicSummaryLink'
									})}`,
									text: `${
										intl.locale === 'fr' ? 'Résumé du Plan stratégique 2022-25' : '2022-25 Summary'
									}`
								}
							]}
						/>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default GovernancePage;
